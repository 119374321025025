//Navbar.js

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "./Navbar.css";
import logo from '../../../src/logo.svg';
// import HamburgerIcon from './HamburgerIcon';


const Navbar = () => {
  const [activeLink, setActiveLink] = useState("");
 

  const handleClick = (page) => { 
    setActiveLink(page);
  }
 
  return (
    <header className='main-header'>
      <nav className='main-nav'>
        <ul className='navbar'>
          <div className="navbar__left">
            <li className='navbar__item'>
              <Link onClick={() => handleClick("Home")} className='navbar__link' to="/"> <img src={logo} className="navbar__logo" alt="logo" /></Link>
            </li>
            <div className='title-logo'>
                    <li className={`navbar__item ${activeLink === "Designer" ? 'navbar__item--clicked' : ''}`}>
              <Link onClick={() => handleClick("Designer")} className='navbar__link' to="/designer">Designer</Link>
            </li>
                    <li className={`navbar__item ${activeLink === "Deveopler" ? 'navbar__item--clicked' : ''}`}>
              <Link onClick={() => handleClick("Deveopler")} className='navbar__link' to="/deveopler">Deveopler</Link>
            </li>
                    <li className={`navbar__item ${activeLink === "Illustrator" ? 'navbar__item--clicked' : ''}`}>
              <Link onClick={() => handleClick("Illustrator")} className='navbar__link' to="/illustrator">Illustrator</Link>
            </li>
          
            </div>
          </div>
         

          <div className="navbar__right">
            <li className={`navbar__item ${activeLink === "Home" ? 'navbar__item--clicked' : ''}`}>
              <Link onClick={() => handleClick("Home")} className='navbar__link' to="/">Home</Link>
            </li>
            <li className={`navbar__item ${activeLink === "About" ? 'navbar__item--clicked' : ''}`}>
              <Link onClick={() => handleClick("About")} className='navbar__link' to="/about">About</Link>
            </li>
            <li className={`navbar__item ${activeLink === "Contact" ? 'navbar__item--clicked' : ''}`}>
              <Link onClick={() => handleClick("Contact")} className='navbar__link' to="/contact">Contact</Link>
            </li>
            {/* <li className={`navbar__item ${activeLink === "Login" ? 'navbar__item--clicked' : ''} navbar__login`}>
              <Link onClick={() => handleClick("Login")} className='navbar__link' to="/admin_portal">Login</Link>
            </li> */}
            {/* <li className={`navbar__item ${activeLink === "Designer" ? 'navbar__item--clicked' : ''}`}>
              <Link onClick={() => handleClick("Designer")} className='navbar__link' to="/designer">Designer</Link>
            </li> */}
          </div>
          {/* </HamburgerIcon> */}

        </ul>
      </nav>
    </header>
  );
};

export default Navbar;