import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBpA_l2XAVhTcErFqfmwxoJ_ch5ieNmxPE",
  authDomain: "website-602f7.firebaseapp.com",
  projectId: "website-602f7",
  storageBucket: "website-602f7.appspot.com",
  messagingSenderId: "1672624262675",
  appId: "1:672624262675:web:f41a8f08251447b146c9e6",
};



const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

