import React from 'react';
import './Designer.css';


const Designer = () => {
   return <div>   
             <div className='Designer-main'>
               <div className='Designer-main_title'>
                 <h4>
                   Graphic Design
                 </h4>
               </div>
             </div>  
           </div>;
 };
 
 export default Designer;