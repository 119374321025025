
// import './About.css'

const NotFound = () => {
  

   return (
    <div className="notFound">
       <div className="notFound-main">
          <h2 className="notFound-main__header">404</h2>
          <h1>Page Not Found</h1>
      <p>The specified file was not found on this website. Please check the URL for mistakes and try again.</p>

 
       </div>
    </div>
   );
 };
 
 export default NotFound;
 