

// AdminPage.js
import React from 'react';
import { auth } from '../../firebase';
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import ToDo from '../../components/ToDo/ToDo'
// import admin_icon from '../Admin/admin_panel_settings_FILL0_wght300_GRAD0_opsz48.svg'
// import logout_icon from '../Admin/logout_FILL0_wght300_GRAD0_opsz48.svg'
import './Admin.css';


const AdminPage = () => {
  const navigate = useNavigate();

  const signOutHandler = async () => {
    try {
      await signOut(auth);
      navigate("/");
    } catch (error) {
      alert(error.message);
    }
  }

  

  return (
    <div className='Admin-container'>
      


      <div>
        <div className="AdminPage">
          {/* <img src={admin_icon} className="admin_icon" alt="logo" /> */}
          
          <h2 className='AdminPage__header'>To Do</h2>
          <ToDo />
        </div>
      </div>

      <div className='button__top'>
        <button className='Admin-main__button Admin__signout' type='submit' onClick={signOutHandler}>
          <span className='hidden-text'>
         sign out
          </span>
        </button>
      </div>

    </div>
  );
};

export default AdminPage;

