// Admin.js
import React, { useState } from 'react';
import { auth } from '../../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import './Admin.css';

const Admin = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const signIn = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/admin_portal_page");
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <div className="Admin">
      <div className="Admin-main">
        <h2 className="Admin-main__header">Administrator</h2>
        <div className="Admin-main__inputs"> 
          <form onSubmit={signIn} className='Admin-main__form'>
            <input
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="User"
              required
            />
            <input
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              required
            />
            <button className="Admin-main__button" type="submit"><span>Sign In</span></button>

          </form>
        </div>
      </div>
    </div>
  );
};

export default Admin;
