// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './App.css';

import Home from './components/Home/Home';
import About from './components/About/About';
import Contact from './components/Contact/Contact';

import Navbar from './components/Navbar/Navbar'; 
import Admin from './store/Admin/Admin';
import AdminPortal from './store/Admin/AdminPage';
import PrivateRoute from './components/PrivateRoute'; 
import NotFound from './components/NotFound/NotFound';
import ToDo from './components/ToDo/ToDo';

import Designer from './components/Portfolio/Designer/Designer';
import Developer from './components/Portfolio/Developer/Deveolper';
import Illustrator from './components/Portfolio/Illustrator/Illustrator';

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <Navbar />
          
        
      
          <Routes>
           <Route path="/about" element={<About />} />
           <Route path="/contact" element={<Contact />} />
           <Route path="/" element={<Home />} />
           <Route path="/admin_portal" element={<Admin />} />
           <Route path="/admin_portal_page" element={<PrivateRoute><AdminPortal /></PrivateRoute>} />
           <Route path="/todos" element={<ToDo />} /> 
           <Route path="*" element={<NotFound />} /> 

           <Route path="/designer" element={<Designer />} /> 
           <Route path="/deveopler" element={<Developer />} /> 
           <Route path="/illustrator" element={<Illustrator />} /> 
         </Routes>
        
        </header>
      </div>
    </Router>
  );
}

export default App;
