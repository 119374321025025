import React from 'react';
import './ToDo.css';


function ToDo() {
  return (
    <div className='ToDoList'>
      
       

      <div className='ToDoList-sub'>
        <div className='ToDoList-sub__item'>
          <ul>
            <li>folders are a bit messy</li>
            <li>put all css in styles folder</li>
            <li>same for pages</li>
            <li>keed admin seperate</li>

          </ul>
        </div>

        <div className='ToDoList-sub__item'>
        <ul>
            <li>use universal root for colors</li>
            <li>simplify all css</li>
            <li>delete all unused css</li>
          </ul>
        </div>
        <div className='ToDoList-sub__item'>
        <ul>
            <li>remove the github files</li>
            <li>check if its still causing an error</li>
            <li>rename files if needed</li>
          </ul>
        </div>
      </div>


    </div>
  );
}

export default ToDo;
