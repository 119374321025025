
import './About.css'
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const About = () => {
  const [activeLink, setActiveLink] = useState("");
 

  const handleClick = (page) => {
    setActiveLink(page);
  }


  return (
    <div className='main'>
      <div className='main-about'>

        <h2 className='about-title'>About Me</h2>
        
        <div className='about-text'>
        Transforming ideas into reality through code, design, and illustration. Based in the UK and ready to take on your next project!
        </div>


      </div>


      <div className="about-route">

             <li className={`about-links ${activeLink === "Designer" ? 'navbar__item--clicked' : ''}`}>
              <Link onClick={() => handleClick("Designer")} className='navbar__link' to="/designer">Designer</Link>
            </li>
            <li className={`about-links ${activeLink === "Deveopler" ? 'navbar__item--clicked' : ''}`}>
              <Link onClick={() => handleClick("Deveopler")} className='navbar__link' to="/deveopler">Deveopler</Link>
            </li>
            <li className={`about-links ${activeLink === "Illustrator" ? 'navbar__item--clicked' : ''}`}>
              <Link onClick={() => handleClick("Illustrator")} className='navbar__link' to="/illustrator">Illustrator</Link>
            </li>
          
          </div>
    </div>
  );
};

export default About;
