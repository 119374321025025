//Home.js
import './Home.css';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from "../../logo.svg"


function Home() {
    const [activeLink, setActiveLink] = useState("");
   
  
    const handleClick = (page) => {
      setActiveLink(page);
    }


  return (
    <div className='main-hero'>
 
        <div className='home-hero '>

          <div className='home-left poppins-'>
                {/* <div className='home_title'>designer</div>
                <div className='home_title'>developer</div>
                <div className='home_title'>illustrator</div> */}

                <div className={`home_title ${activeLink === "Designer" ? 'navbar__item--clicked' : ''}`}>
                  <Link onClick={() => handleClick("Designer")} className='navbar__link' to="/designer">Design</Link>er
                </div>
                <div className={`home_title ${activeLink === "Deveopler" ? 'navbar__item--clicked' : ''}`}>
              <Link onClick={() => handleClick("Deveopler")} className='navbar__link' to="/deveopler">develop</Link>er
            </div>
            <div className={`home_title ${activeLink === "Illustrator" ? 'navbar__item--clicked' : ''}`}>
              <Link onClick={() => handleClick("Illustrator")} className='navbar__link' to="/illustrator">Illustrat</Link>or
            </div>

          </div>

            <div className='home-right'>
            Transforming ideas into reality through code, design, and illustration. 
              <div>
                <a href='../contact'>Ready for the next project!</a></div>
            </div>
        </div>
 
        <div className='second-div'>
          {/* <div><a href='./'>CALL TO ACTION</a></div> */}
        </div>
        <div className='third-div'>
         
          <img className='third-div-logo' src={logo} alt="logo" />

          <div className='third-div-title'><div>This site is currently under construction, but check back soon for a fresh new look and exciting updates.</div></div>

        </div>


    </div>


  );
}

export default Home;

