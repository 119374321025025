import React from 'react';
import './Illustrator.css';


const Illustrator = () => {
   return <div>   
             <div className='Illustrator-main'>
               <div className='Illustrator-main_title'>
                 <h4>
                   Illustrator
                 </h4>
               </div>
             </div>
               
           </div>;
 };
 
 export default Illustrator;