import { Link } from 'react-router-dom';
import './Contact.css';


const Contact = () => {

  return <div>   
            <div className='contact-main'>
              <div className='contact-main_title'>

                <div>This site is currently under construction, but check back soon for a fresh new look and exciting updates.</div>
                  <div className='contact_link'>You can still reach us at  <a href="mailto:info@samjarrah.com">info@samjarrah.com</a> for any inquiries.</div>
                 
              </div>
            </div>  
            <Link to="/admin_portal">
                <div className="admin-page" alt="admin page" >&#9860;</div>
              </Link>
          </div>;
};

export default Contact;
